var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-4"},[_c('v-toolbar',{staticClass:"mb-2"},[_c('v-toolbar-title',[_vm._v("Schedule Change Details: "+_vm._s(_vm.name)+" - "+_vm._s(_vm.bannerId))]),_c('v-spacer'),_c('v-btn',{attrs:{"to":"/student/schedule/admin","text":""}},[_vm._v("Back to List")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":12,"md":"6"}},[_c('v-expansion-panels',{model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_vm._l((_vm.sections),function(ref){
var label = ref.label;
var changes = ref.changes;
return [(changes.length > 0)?_c('v-expansion-panel',{key:'panel-' + label},[_c('v-expansion-panel-header',[_vm._v(_vm._s(label))]),_c('v-expansion-panel-content',{style:('background-color:#' + (_vm.isDark ? '333333' : 'eeeeee'))},[_vm._l((changes),function(ref){
var _id = ref._id;
var action = ref.action;
var status = ref.status;
var course = ref.course;
var crn = ref.crn;
var gradeMode = ref.gradeMode;
var approvals = ref.approvals;
return _c('change',{key:'pending-' + _id,attrs:{"main-id":_vm.id,"change-id":_id,"action":action,"status":status,"course":course,"crn":crn,"approvals":approvals,"advisors":_vm.advisors,"grade-mode":gradeMode}})}),(label === 'Initial Review')?_c('v-btn',{staticClass:"mt-4",attrs:{"color":"success"},on:{"click":_vm.sendToApprovers}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-check-circle")]),_vm._v(" Send To Approvers ")],1):_vm._e()],2)],1):_vm._e()]})],2)],1),_c('v-col',{attrs:{"cols":12,"md":"6"}},[_c('v-card',{style:('background-color:#' + (_vm.isDark ? '333333' : 'eeeeee'))},[_c('v-toolbar',{attrs:{"dense":""}},[_c('v-toolbar-title',[_vm._v("Timeline")]),_c('v-spacer'),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("fal fa-comment-plus")])],1)]}}]),model:{value:(_vm.addCommentDialog),callback:function ($$v) {_vm.addCommentDialog=$$v},expression:"addCommentDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Add Comment")]),_c('v-card-text',[_c('p',[_vm._v("Add a comment/message. This can be visible to the student, or can be made only visible to other instructors, advisors, and the Records office. No notification is sent to the student unless it is visible to the student and the box is checked.")]),_c('v-textarea',{attrs:{"label":"Comment/Message","outlined":""},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}}),_c('p',[_vm._v("Should this comment be visible to or hidden from the student?")]),_c('v-switch',{attrs:{"label":(_vm.visibleToStudent ? 'Visible to' : 'Hidden from') + ' the student',"hide-details":""},model:{value:(_vm.visibleToStudent),callback:function ($$v) {_vm.visibleToStudent=$$v},expression:"visibleToStudent"}}),(_vm.visibleToStudent)?_c('v-checkbox',{attrs:{"label":"Send a notification to the student with this message","hide-details":""},model:{value:(_vm.notifyStudent),callback:function ($$v) {_vm.notifyStudent=$$v},expression:"notifyStudent"}}):_vm._e()],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.addCommentDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"disabled":_vm.comment === '',"color":"success","text":""},on:{"click":_vm.addComment}},[_vm._v("Add Comment")])],1)],1)],1)],1),_c('v-card-text',[_c('v-timeline',{attrs:{"dense":""}},_vm._l((_vm.timeline),function(ref,index){
var _id = ref._id;
var color = ref.color;
var icon = ref.icon;
var pidm = ref.pidm;
var name = ref.name;
var date = ref.date;
var text = ref.text;
var expanded = ref.expanded;
return _c('v-timeline-item',{key:'message-' + _id,class:!(icon) && pidm !== _vm.user.pidm ? 'reverse-icon' : '',attrs:{"color":color || 'info'},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v(_vm._s(icon || 'fal fa-comment'))])]},proxy:true}],null,true)},[_c('v-card',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.timeline[index].expanded = !expanded}}},[_c('v-card-text',{style:(expanded ? 'padding-bottom:10px;overflow:auto' : 'overflow:hidden;text-overflow:ellipsis;white-space:nowrap;padding-bottom:10px;'),domProps:{"innerHTML":_vm._s(text)}}),_c('v-card-text',{staticStyle:{"font-size":".8em","opacity":".7","padding-top":"0"}},[_vm._v(_vm._s(name)+" -- "+_vm._s(_vm.stringFormatDate(date)))])],1)],1)}),1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }